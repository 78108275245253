import { useGoogleLogin } from "@react-oauth/google";
import { FC } from "react";
import { useAppDispatch } from "../app/store";
import { login } from "../features/account/accountSlice";
import { Button, ButtonProps } from "@mui/material";

type Props = Omit<ButtonProps, "onClick">;

export const LoginButton: FC<Props> = ({ ...props }) => {
    const dispatch = useAppDispatch();

    const googleLogin = useGoogleLogin({
        onError: () => console.log("Error"),
        onSuccess: (codeResponse) => dispatch(login(codeResponse.code)),
        flow: "auth-code",
    });

    return (
        <Button onClick={googleLogin} variant="contained" {...props}>
            Logga in
        </Button>
    );
};
