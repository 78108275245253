import {
    Box,
    Collapse,
    IconButton,
    InputAdornment,
    OutlinedInput,
    OutlinedInputProps,
    SxProps,
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router";

type Props = {
    alwaysExpanded?: boolean;
    size?: OutlinedInputProps["size"];
    sx?: SxProps;
};

export const SearchField: FC<Props> = ({ alwaysExpanded, size, sx }) => {
    const navigate = useNavigate();

    const inputRef = useRef<HTMLInputElement>(null);
    const [expanded, setExpanded] = useState(alwaysExpanded ?? false);
    const [input, setInput] = useState("");

    const handleSearch = () => {
        if (!expanded) {
            setExpanded(true);
            return;
        }

        navigate(`/cards?q=${input}`);
        setExpanded(false);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                ...sx,
            }}
        >
            <Collapse
                orientation="horizontal"
                in={expanded}
                sx={{
                    width: "100% !important",
                    "& .MuiCollapse-wrapperInner": { width: "100%" },
                }}
            >
                <OutlinedInput
                    inputRef={inputRef}
                    endAdornment={
                        <InputAdornment position="end">
                            {alwaysExpanded && (
                                <IconButton
                                    onClick={handleSearch}
                                    color="primary"
                                >
                                    <Search sx={{ transform: "scale(2.0)" }} />
                                </IconButton>
                            )}
                            {!alwaysExpanded && (
                                <IconButton
                                    onClick={() => setExpanded(false)}
                                    color="primary"
                                >
                                    <Close sx={{ transform: "scale(2.0)" }} />
                                </IconButton>
                            )}
                        </InputAdornment>
                    }
                    onChange={(event) => setInput(event.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") handleSearch();
                    }}
                    onBlur={() => setExpanded(false)}
                    size={size ?? "small"}
                    value={input}
                    sx={{ width: "100%" }}
                />
            </Collapse>
            <Collapse orientation="horizontal" in={!expanded}>
                <IconButton
                    onClick={() => {
                        setExpanded(true);
                        // A small delay is needed to ensure the input is focused
                        setTimeout(() => inputRef.current?.focus(), 100);
                    }}
                    color="primary"
                >
                    <Search sx={{ transform: "scale(2.0)" }} />
                </IconButton>
            </Collapse>
        </Box>
    );
};
