import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { App } from "./App";
import { store } from "./app/store";
import { initI18n } from "./i18n";
import { ThemeProvider, createTheme } from "@mui/material/styles";

initI18n();

const theme = createTheme({
    palette: {
        background: {
            default: "#F4FFDD",
            paper: "#FFE0C1",
        },
        primary: {
            main: "#AEE266",
            light: "#a86d7f",
            dark: "#6b142f",
            contrastText: "#000000",
        },
        secondary: {
            main: "#8E9AAF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    border: "2px solid #000000",
                },
            },
        },
    },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);
