export const translations = {
    accountPage: {
        title: "Mitt konto",
        numPublicCollections: "Antal offentliga samlingsmål",
        numPrivateCollections: "Antal privata samlingsmål",
        numCards: "Antal kort i min ägo",
        numDistinctExpansions: "Antal expansioner jag har kort ifrån",
    },
    addToCollectionDialog: {
        add: "Lägg till",
        cancel: "Avbryt",
        collection: "Samlingsmål",
        cardVariant: "Variant",
        condition: "Skick",
        quantity: "Antal",
        title: "Lägg till i samlingsmål",
    },
    browseCardsPage: {
        filters: {
            expansions: "Expansioner",
            searchInput: "Söksträng",
            searchInputPlaceholder: "Namn, nummer eller expansion",
        },
        table: {
            addToCollection: "Lägg till kort i samlingsmål",
            cardName: "Namn (#)",
            expansionName: "Expansion",
            image: "Bild",
        },
        showing: "Visar {{from}} - {{to}} av {{total}}",
        title: "Hitta kort",
    },
    browseExpansionsPage: {
        title: "Hitta expansioner",
        sorting: "Sortera",
        sortingName: "Namn",
        sortingReleaseDate: "Lanseringsdatum",
    },
    cardPage: {
        cardDetails: {
            cardNumber: "#",
            expansionName: "Expansion",
        },
        collectionsWithCardInIt: {
            addToCollection: "Lägg till i samlingsmål",
            noCollectionsWithCardInIt:
                "Kortet finns inte i något av dina samlingsmål.",
            table: {
                collection: "Samlingsmål",
                cardVariant: "Variant",
                condition: "Skick",
                quantity: "Antal",
            },
            title: "Dina samlingsmål som innehåller kortet",
        },
        inventory: {
            numCardsInInventory: "Antal kort i min ägo: {{numCards}}",
            numCardsByCondition: "Antal fördelat på skick och variant:",
        },
    },
    cardVariant: {
        standard: "Standard",
        "first-edition": "1st ed",
        shadowless: "Shadowless",
        "reverse-holo": "Reverse holo",
        error: "Error",
        unlimited: "Unlimited",
        "winner-stamp": "Winner stamp",
        prerelease: "Prerelease",
    },
    collectionsPage: {
        confirmDeleteDialog: {
            cancel: "Avbryt",
            delete: "Ta bort",
            text: 'Är du säker på att du vill ta bort din samling "{{collectionName}}"?',
            title: "Bekräfta borttagning",
        },
        createCollection: "Ny samling",
        createCollectionDialog: {
            cancel: "Avbryt",
            create: "Skapa",
            title: "Ny samling",
        },
        collectionForm: {
            collectionName: "Namn",
            publicVisibility: "Offentlig samling",
        },
        editCollectionDialog: {
            title: "Ändra samling",
            cancel: "Avbryt",
            save: "Spara",
        },
        noCollections:
            'Du har inga samlingar för tillfället. Tryck på "Ny samling" för att skapa en.',
        publicVisibility: {
            private: "Privat",
            public: "Offentlig",
            title: "Synlighet",
        },
        showCollection: "Visa samling",
        editCollection: "Ändra",
        deleteCollection: "Ta bort",
        title: "Mina samlingar",
    },
    header: {
        accountMenu: {
            login: "Logga in",
            logout: "Logga ut",
            myCards: "Mina kort",
            myCollections: "Mina samlingar",
            myAccount: "Mitt konto",
        },
        navigation: {
            browseCards: "Hitta kort",
            browseExpansions: "Hitta expansioner",
            home: "Hem",
            login: "Logga in",
            logout: "Logga ut",
        },
    },
    homePage: {
        documentTitle: "Hem",
        title: "Korthyllan",
    },
    inventoryPage: {
        filters: {
            expansions: "Expansioner",
        },
        table: {
            cardName: "Kort",
            cardNumber: "#",
            cardVariant: "Variant",
            condition: "Skick",
            expansionName: "Expansion",
            image: "Bild",
            quantity: "Antal",
        },
        title: "Mina kort",
    },
    loginPage: {
        email: "E-postadress",
        login: "Logga in",
        password: "Lösenord",
        title: "Logga in",
    },
    notFoundPage: {
        pageNotFound: "Sidan kunde inte hittas",
        title: "Sidan finns inte",
    },
};
