import {
    AccessTime,
    AccountBox,
    Collections,
    Logout,
    Star,
    TrendingFlat,
} from "@mui/icons-material";
import {
    Box,
    Button,
    List,
    ListItem,
    Stack,
    SxProps,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { ComponentType, FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/store";
import { Link } from "../../components/Link";
import { LoginButton } from "../../components/LoginButton";
import { Page } from "../../components/Page";
import { setDocumentTitle } from "../../utils/setDocumentTitle";
import { selectIsAuthenticated } from "../account/accountSlice";
import { setTitle } from "../header/headerSlice";
import { SearchField } from "../header/SearchField";

export const HomePage: FC = () => {
    const { t } = useTranslation("homePage");
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);

    useEffect(() => {
        dispatch(setTitle(t("title")));
        setDocumentTitle(t("documentTitle"));
    }, [dispatch, t]);

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <Page sx={{ px: 0 }}>
            <Stack spacing={2} mt={8}>
                {!isAuthenticated ? (
                    <Stack
                        spacing={desktop ? 8 : 2}
                        sx={{
                            px: 2,
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "center",
                            zindex: 1,
                        }}
                    >
                        <Box sx={{ zIndex: 1 }}>
                            <Typography variant="h4" fontWeight={700}>
                                Håll koll på dina kort
                            </Typography>
                            <List sx={{ fontSize: "1.5rem" }}>
                                <ListItem>Skapa samlingar</ListItem>
                                <ListItem>Se andras samlingar</ListItem>
                                <ListItem>Inspireras</ListItem>
                            </List>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                width: "100%",
                                zIndex: 1,
                            }}
                        >
                            <LoginButton
                                sx={{ fontSize: "1.25rem", px: 5, py: 2 }}
                            />
                        </Box>
                    </Stack>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            zIndex: 1,
                            pb: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                rowGap: "3rem",
                                width: "80%",
                            }}
                        >
                            <Shortcut
                                href="account"
                                title="Profil"
                                IconComponent={AccountBox}
                            />
                            <Shortcut
                                href="my-collections"
                                title="Samlingar"
                                IconComponent={Collections}
                            />
                            <Shortcut
                                href="my-cards"
                                title="Kort"
                                IconComponent={Collections}
                            />
                            <Shortcut
                                href="/logout"
                                title="Logga ut"
                                IconComponent={Logout}
                            />
                        </Box>
                    </Box>
                )}
                <Box sx={{ position: "relative" }}>
                    <Box
                        sx={{
                            height: "15rem",
                            width: "88%",
                            mx: "6%",
                            backgroundColor: "background.default",
                            borderRadius: "0 0 50% 50%",
                            position: "absolute",
                            top: "-10rem",
                        }}
                    />
                    <Stack
                        spacing={desktop ? 8 : 1}
                        sx={{
                            backgroundColor: "#FFE0C1",
                            px: 2,
                            py: 6,
                            minHeight: "50vh",
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "center",
                        }}
                    >
                        <Link
                            href="/cards"
                            sx={{
                                color: "black",
                                fontSize: "2rem",
                                fontWeight: 700,
                                textDecoration: "none",
                            }}
                        >
                            Hitta kort <TrendingFlat />
                        </Link>
                        <Link
                            href="/expansions"
                            sx={{
                                color: "black",
                                fontSize: "2rem",
                                fontWeight: 700,
                                textDecoration: "none",
                            }}
                        >
                            Hitta expansioner <TrendingFlat />
                        </Link>
                        <Box sx={{ pt: 3, width: "100%" }}>
                            <Typography fontWeight={700}>
                                Sök kort eller expansion
                            </Typography>
                            <SearchField alwaysExpanded size="medium" />
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ px: 2, pb: 2 }}>
                    <Typography gutterBottom variant="h4" fontWeight={700}>
                        Utforska andras samlingar
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            zIndex: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                rowGap: "3rem",
                                width: "80%",
                            }}
                        >
                            <Shortcut
                                href="/collections?sort=popular"
                                title="Populära"
                                IconComponent={Star}
                            />
                            <Shortcut
                                href="/collections?sort=updated"
                                title="Nyligen uppdaterade"
                                IconComponent={AccessTime}
                            />
                        </Box>
                    </Box>
                </Box>
            </Stack>
        </Page>
    );
};

const Shortcut: FC<{
    href: string;
    title: string;
    IconComponent: ComponentType<{ sx: SxProps }>;
}> = ({ href, title, IconComponent }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Button
                href={href}
                variant="contained"
                sx={{ width: "8rem", height: "8rem" }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <IconComponent
                        sx={{
                            transform: "scale(2.0)",
                            height: "3rem",
                        }}
                    />
                    <Typography textAlign="center">{title}</Typography>
                </Box>
            </Button>
        </Box>
    );
};
