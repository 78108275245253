import {
    AppBar,
    Box,
    Divider,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from "@mui/material";
import {
    AccountCircle,
    ChevronLeft,
    Menu as MenuIcon,
} from "@mui/icons-material";
import React, { FC, MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import { selectTitle } from "./headerSlice";
import { Navigation } from "./Navigation";
import { SearchField } from "./SearchField";
import { logout, selectIsAuthenticated } from "../account/accountSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../app/store";

export const Header: FC = () => {
    const { t } = useTranslation("header");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const title = useSelector(selectTitle);
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [accountMenuAnchor, setAccountMenuAnchor] =
        useState<HTMLElement | null>(null);
    const [accountMenuIsOpen, setAccountMenuIsOpen] = useState(false);

    const handleAccountButtonClick = (event: MouseEvent<HTMLElement>) => {
        setAccountMenuAnchor(event.currentTarget);
        setAccountMenuIsOpen(true);
    };

    const handleAccountMenuItemClick = (url: string) => {
        setAccountMenuIsOpen(false);
        navigate(url);
    };

    return (
        <>
            <AppBar
                position="static"
                color="transparent"
                elevation={0}
                sx={{ py: 2, px: 1 }}
            >
                <Toolbar>
                    <Box
                        display="flex"
                        flexDirection="row"
                        flexGrow={1}
                        justifyContent="space-between"
                    >
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="row"
                            flexShrink={0}
                        >
                            <IconButton
                                color="primary"
                                edge="start"
                                onClick={() => setMenuIsOpen(true)}
                            >
                                <MenuIcon sx={{ transform: "scale(2.0)" }} />
                            </IconButton>
                            <Typography variant="h6" sx={{ ml: 1 }}>
                                {title}
                            </Typography>
                        </Box>
                        <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="row"
                        >
                            <SearchField sx={{ mx: 1 }} />
                            <IconButton
                                onClick={handleAccountButtonClick}
                                color="primary"
                            >
                                <AccountCircle
                                    sx={{ transform: "scale(2.0)" }}
                                />
                            </IconButton>
                            <Menu
                                anchorEl={accountMenuAnchor}
                                anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                }}
                                onClose={() => setAccountMenuIsOpen(false)}
                                open={accountMenuIsOpen}
                                transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                }}
                            >
                                {isAuthenticated &&
                                    accountMenuItems.map((item) => (
                                        <MenuItem
                                            key={item.labelKey}
                                            onClick={() =>
                                                handleAccountMenuItemClick(
                                                    item.to
                                                )
                                            }
                                        >
                                            {t(item.labelKey)}
                                        </MenuItem>
                                    ))}
                                {isAuthenticated ? (
                                    <MenuItem
                                        onClick={() => {
                                            dispatch(logout());
                                            handleAccountMenuItemClick("/");
                                        }}
                                    >
                                        {t("accountMenu.logout")}
                                    </MenuItem>
                                ) : (
                                    <MenuItem
                                        onClick={() =>
                                            handleAccountMenuItemClick("/login")
                                        }
                                    >
                                        {t("accountMenu.login")}
                                    </MenuItem>
                                )}
                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={menuIsOpen}
                onClose={() => setMenuIsOpen(false)}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        px: 1,
                        py: 0,
                        justifyContent: "flex-start",
                    }}
                >
                    <IconButton onClick={() => setMenuIsOpen(false)}>
                        <ChevronLeft />
                    </IconButton>
                </Box>
                <Divider />
                <Navigation onItemClick={() => setMenuIsOpen(false)} />
            </Drawer>
        </>
    );
};

const accountMenuItems = [
    { labelKey: "accountMenu.myAccount", to: "/account" },
    { labelKey: "accountMenu.myCards", to: "/my-cards" },
    { labelKey: "accountMenu.myCollections", to: "/my-collections" },
];
